import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import { Container, Footer } from './styles';
import { ModalFooter } from "react-bootstrap";

export default function ModalFormTemplate({ newTemplate, setShow, ...props }) {

  return (
    <Modal
      {...props}
      centered
      onHide={() => {
        setShow(false)
      }}
    >
      <ModalHeader closeButton>
        <Container>
          <label className="title">Impressão das Interpretações e Laudos</label>
        </Container>
      </ModalHeader>
      <ModalBody >
        <Container>
          <label className="field">Serão impressas todos as interpretações geradas para a(s) amostra(s).</label>
          <label className="field"> Deseja continuar?</label>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Footer>
          <div className="row div-footer">
            <button className="btn button-cancel" onClick={() => {
              setShow(false)
            }} >Cancelar</button>
            <button className="btn button-confirm" onClick={() => { newTemplate() }} >Confirmar</button>            
          </div>
        </Footer>
      </ModalFooter>
    </Modal>
  );
}