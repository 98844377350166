import React, { useEffect, useState } from 'react';

import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';

const SupplierForm = () => {
    const activeOptions = [
        { value: true, text: "Sim" },
        { value: false, text: "Não" }
    ];

    const cultures = [
        {
            label: "Descrição",
            name: "description",
            type: "input",
        },
        {
            label: "Link",
            name: "link",
            type: "input",
        },
        {
            label: "Ativo",
            name: "isActive",
            type: "select",
            options: activeOptions
        },
    ];

    return (
        <FormCrud formName={"supplier"} title={"Laboratório"} fields={cultures} />
    );
}

export default SupplierForm;
