import React, { useEffect, useState } from 'react';

import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';

const RegionForm = () => {
    const [TenantOptions, setTenantOptions] = useState([]);
    const [isVisible, setIsVisible] = useState(false);

    const activeOptions = [
        { value: true, text: "Sim" },
        { value: false, text: "Não" }
    ];

    const regions = [
        {
            label: "Nome",
            name: "name",
            type: "input",
        },
        {
            label: "Empresa",
            name: "tenantID",
            type: "select",
            options: TenantOptions,
            visible: isVisible,
            required: false,
        },
        {
            label: "Ativo",
            name: "isActive",
            type: "select",
            options: activeOptions
        },
    ]

    const loadData = async () => {
        Api.get('Tenant/isTenant').then((result) => {
            if (result.data.response) {
                setIsVisible(true)

                Api.get('Tenant/getAllItems').then((resultTenant) => {
                    if (resultTenant && resultTenant.data) {
                        const options = resultTenant.data.response.map(item => ({ value: item.tenantID, text: item.name }));
        
                        setTenantOptions(options);
                    }
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                });
            }
            else{
                setIsVisible(false)
                var options = [{ value: "", text: "Selecione" }];
                setTenantOptions(options);            
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        TenantOptions.length > 0 ? <FormCrud formName={"region"} title={"Região"} fields={regions} /> :<></>
    );
}

export default RegionForm;
