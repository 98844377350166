import React, { useEffect, useState } from 'react';

import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';


const FarmerForm = () => {   
    const [isTenant, setIsTenant] = useState(true);
    const [branchsOptions, setBranchsOptions] = useState(null);    
    
    const activeOptions = [
        { value: true, text: "Sim" },
        { value: false, text: "Não" }
    ];

    const farmers = [
        {
            label: "Nome",
            name: "name",
            type: "input",
            //disabled : isTenant,
        },
        {
            label: "Localidade",
            name: "place",
            type: "input",
            //disabled : isTenant,
        },
        {
            label: "Documento",
            name: "documentNumber",
            type: "input",
            //disabled : isTenant,
        },
        /*{
            label: "Código externo",
            name: "externalCode",
            type: "input",
            //disabled : isTenant,
            required: false,
        },*/
        {
            label: "Identificação",
            name: "identificationNumber",
            type: "input",
        },
        {
            label: "Filiais",
            name: "branchs",
            type: "select",
            options: branchsOptions,
            multiple: true,
            required: false,
        },
        {
            label: "Ativo",
            name: "isActive",
            type: "select",
            options: activeOptions
        },
    ]

    const loadData = async () => {
        Api.get('Tenant/isTenant').then((result) => {
            if (result.data.response) {
                setIsTenant(false)
            }
            else {
                setIsTenant(true)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });        

        Api.get('Branch/GetAllBranchs').then((result) => {
            if (result && result.data) {
                const branchsOptions = result.data.response.map(item => ({ value: item.branchID, text: item.description, key: item.cnpj }));
                setBranchsOptions(branchsOptions);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        
        branchsOptions != null  ? <FormCrud formName={"farmer"} title={"Produtor"} fields={farmers} /> : <></>
    );
}

export default FarmerForm;
