import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useLocation, useHistory } from 'react-router-dom';
import Logo from '../../assets/images/data_results_logo.png';
import { useAuth } from '../../hooks/auth';
import Api, { exceptionNotificationAPI } from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { Container } from './styles';
import { error_message, success_message } from '../../components/Toast/index.jsx';

const SignIn = () => {
    const { signIn, user, ssoSignIn } = useAuth();
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginStep, setLoginStep] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [signInResult, setSignInResult] = useState('');
    const location = useLocation();
    const [forgotPasswordClicked, setforgotPasswordClicked] = useState('');
    const [sendEmail, setSendEmail] = useState(false);

    const populateCall = async () => {
        Api.post('/populate').then((result) => {
            history.push('/');
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        if (user) {
            if (location.state !== undefined && location.state.user) {
                returnRole(location.state.user);
            }
            else {
                history.push("/");
            }

        }
    }, [history, user]);

    useEffect(() => {
        if (document.location.href.indexOf('/populate') > -1) {
            populateCall();
        }
    }, [document.location.href]);

    const handleSubmit = async () => {

        try {
            const schema = Yup.object().shape({
                username: Yup.string()
                    .required('Username is required'),
                password: Yup.string().required('Password is required'),
            });

            await schema.validate({ username, password }, {
                abortEarly: false,
            });

            var result = await signIn({
                username: username,
                password: password,
                resultResponse: null,
                selectedRole: null
            });

            if (result.data.response.user.roles.length > 1) {
                setUserRoles([...result.data.response.user.roles]);
                setLoginStep('roleSelection');
                setSignInResult(result);
            }
            else if (result.data.response.user.roles !== null && result.data.response.user.roles.length === 1) {
                setLoginStep(null);

                var result = await signIn({
                    username: null,
                    password: null,
                    resultResponse: result,
                    normalizedName: result.data.response.user.roles[0].normalizedName
                });
            }

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                getValidationErrors(error);

                return;
            }

            exceptionNotificationAPI(error);
        }
    };

    const returnRole = async (user) => {
        try {

            setUserRoles([...user.roles]);
            setLoginStep('roleSelection');
            setSignInResult(user);


        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                getValidationErrors(error);

                return;
            }

            exceptionNotificationAPI(error);
        }
    };

    const onEnterPress = (e) => {
        if (e.key == 'Enter') {
            handleSubmit();
        }
    }

    const selectRole = async (e) => {
        setUsername(e.target.value);
        setSelectedRole(e.target.value);
    }

    const onSelectRoleClick = () => {
        handleRoleSignIn();
    }

    const handleRoleSignIn = async () => {
        if (selectedRole != '') {
            var result = await signIn({
                username: null,
                password: null,
                resultResponse: signInResult,
                normalizedName: selectedRole
            });

            history.push("/");
        }
    }

    const returnToUsername = async () => {
        //setLoginStep("");
        history.goBack()
    }

    const onSendEmail = async () => {
        setSendEmail(true)
    }

    const onForgotPasswordClicked = async () => {
        try {
            setforgotPasswordClicked(true);
            setLoginStep("forgotPassword");
            const result = await Api.post('User/forgotPassword', {
                'username': username,
                'Password': ''

            });

            if (result.data.response) {
                success_message("E-mail enviado, Por favor, siga o processo de redefinição de senha no e-mail.")
                history.push("/");
            }
            else {
                error_message("Usuário não encontrado")
            }
        }
        catch (error) {
            exceptionNotificationAPI(error);
        }
    }

    return (
        <Container >
            <div alt="Soil Analysis" className="main-div" >
                <div className="card">
                    {
                        sendEmail === true ?
                            <div className="card-body">
                                <img src={Logo} alt="Soil Analysis" className='logo' />
                                <div className="form">
                                    <label>
                                        Redefinir sua senha
                                    </label>
                                </div>
                                <div className="form">
                                    <label>
                                        Usuário
                                    </label>
                                    <input className="form-control" id="UserName" name="userName" placeholder="" onChange={(e) => setUsername(e.target.value)} required={true} type="text" onKeyPressCapture={(e) => onEnterPress(e)} />
                                </div>
                                <button className="btn" onClick={onForgotPasswordClicked}>Enviar</button>
                            </div>
                            :
                            loginStep === "" ?

                                <div className="card-body">
                                    <img src={Logo} alt="Soil Analysis" className='logo' />
                                    <div className="form">
                                        <label>
                                            Usuário
                                        </label>
                                        <input className="form-control" id="UserName" name="userName" placeholder="" onChange={(e) => setUsername(e.target.value)} required={true} type="text" onKeyPressCapture={(e) => onEnterPress(e)} />
                                    </div>
                                    <div className="form">
                                        <label>
                                            Senha
                                        </label>
                                        <input className="form-control" id="Senha" name="Senha" placeholder="" onChange={(e) => setPassword(e.target.value)} required={true} type="password" onKeyPressCapture={(e) => onEnterPress(e)} />
                                    </div>
                                    <a onClick={onSendEmail}>Redefinir sua senha</a>

                                    <button className="btn" onClick={handleSubmit}>Entrar</button>
                                </div>
                                :
                                <>
                                    {
                                        loginStep === "roleSelection" ?
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label>
                                                        Selecione sua função para esta sessão
                                                    </label>
                                                    <select className="form-control" onChange={(e) => selectRole(e)} value={selectedRole ? selectedRole : ""}>
                                                        <option key={0} value={""}>Selecione uma função</option>
                                                        {

                                                            userRoles ?
                                                                userRoles.map(userRole => {
                                                                    return <option key={userRole.id} value={userRole.normalizedName}>{userRole.description}</option>
                                                                })
                                                                :
                                                                <></>
                                                        }
                                                    </select>
                                                </div>

                                                <button className="btn" onClick={onSelectRoleClick}>Continuar</button>
                                                <button className="btn return-button" onClick={returnToUsername}>Retornar</button>
                                            </div>
                                            :
                                            <></>
                                    }
                                </>
                    }


                </div>

            </div>

        </Container>
    );
};

export default SignIn;
