import axios from 'axios';
import { error_message } from '../components/Toast/index.jsx';

const Api = axios.create({
    // Local
    //baseURL: 'https://localhost:7103/',
    // SoilAnalysis Dev
    //baseURL: 'https://analyticalcenterapi-dev.azurewebsites.net/'
    // SoilAnalysis QA
    //baseURL: 'https://analyticalcenterapi-qa.azurewebsites.net/'
    
    // SoilAnalysis PRD
    baseURL: 'https://analyticalcenterapi.azurewebsites.net/'
});

export const exceptionNotificationAPI = (error) => {

    if (/500/.test(error.message)) {
        error_message('Failed to connect Web Service (500).');
    } else if (/404/.test(error.message)) {
        error_message(error.message);
    } else if (/400/.test(error.message)) {
        let notifications = error.response.data.notifications;
        if (notifications && notifications.length > 0) {
            notifications.forEach((not) => {
                error_message(not.message);
            })
        }
    } else {
        error_message(error.message);
    }
}

export default Api;