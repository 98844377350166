import React, { useEffect, useState, forwardRef, useRef } from 'react';
import api, { exceptionNotificationAPI } from '../../services/api';
import { Box, Container, InputBase, InputAdornment, Button, Checkbox, Grid, styled } from '@material-ui/core';
import { useStyles } from './styles';
import TableLoading from '../TableLoading';
import LoadingEvents from '../../components/LoadingEvents';
import { useLocation, useHistory } from 'react-router-dom';
import ArrowLeft from '../../assets/icons/arrowLeft.png';
import { error_message, success_message } from '../Toast/index.jsx';
import Select from 'react-select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import paper from '../../assets/icons/paper.png';
import AnalyseSampleDetailItemInterpretationRecommendationResult from '../AnalyseSampleDetailItemInterpretationRecommendationResult';
import tashIcon from '../../assets/icons/trash_red.png';
import okIcon from '../../assets/icons/ok.png';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '../../hooks/auth';
import { ROLE } from '../../config/roles';

const AnalyseSampleDetailItemInterpretationRecommendation = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingEvents, setIsLoadingEvents] = useState(false);
    const location = useLocation();
    const [analyseSampleReturn, setAnalyseSampleReturn] = useState([]);
    const [selectedFarmer, setSelectedFarmer] = useState();
    const [farmers, SetFarmers] = useState([]);
    const [farmer, setFarmer] = useState();
    const [selectedBranch,  setSelectedBranch] = useState();
    const [branchs, SetBranchs] = useState([]);
    const [branch, setBranch] = useState();
    const [selectedInstructor,  setSelectedInstructor] = useState();
    const [instructors, SetInstructors] = useState([]);
    const [instructor, setInstructor] = useState();
    const [selectedSupplier,  setSelectedSupplier] = useState();
    const [suppliers, SetSuppliers] = useState([]);
    const [supplier, setSupplier] = useState();
    const [valueTab, setValueTab] = useState(0);
    const [parameterTemplateItem, setParameterTemplateItem] = useState([]);
    const [lstAnalyseSampleResultInterpretation, setLstAnalyseSampleResultInterpretation] = useState([]);
    const [analyseSampleParameterTemplateID, setAnalyseSampleParameterTemplateID] = useState();
    const [agronomist, setAgronomist] = useState(null);

    const [analyseSampleParameterTemplate, setAnalyseSampleParameterTemplate] = useState();
    const [analyseSampleParameterTemplates, setAnalyseSampleParameterTemplates] = useState([]);
    const [selectedAnalyseSampleParameterTemplate, setSelectedAnalyseSampleParameterTemplate] = useState();

    const [observationList, setObservationList] = useState([]);
    const [counterNewObservation, setCounterNewObservation] = useState(0);

    const { userRoles, user } = useAuth();

    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };

    useEffect(() => {
        let cancel = false;
        setIsLoading(true)

        getAnalyseSample((options) => {
            if (options) {
                setAnalyseSampleReturn(options)
                if (options.farmerCultureRegion) {

                    loadBranchs((optionsBranch) => {
                        SetBranchs(optionsBranch)
    
                        if (optionsBranch && optionsBranch.length > 0) {
                            if (options.farmerCultureRegion.branch) {                                
                                setSelectedBranch(options.farmerCultureRegion.branchID)                                
                                setBranch(options.farmerCultureRegion.branch.description)
                            }
                            //setIsLoading(false)
                        }
            
                    });

                    loadInstructors((optionsInstructor) => {
                        SetInstructors(optionsInstructor)
    
                        if (optionsInstructor && optionsInstructor.length > 0) {
                            if (options.instructor) {            
                                setSelectedInstructor(options.instructorID)                                
                                setInstructor(options.instructor.name)
                            }
                            //setIsLoading(false)
                        }
            
                    });

                    loadSuppliers((optionsSupplier) => {
                        SetInstructors(optionsSupplier)
    
                        if (optionsSupplier && optionsSupplier.length > 0) {
                            if (options.supplier) {            
                                setSelectedSupplier(options.supplierID)                                
                                setSupplier(options.supplier.description)
                            }
                            //setIsLoading(false)
                        }
            
                    });

                    loadfarmerCultureRegion(options.farmerCultureRegionID, (optionsFarmer) => {
                        SetFarmers(optionsFarmer)
                        if (optionsFarmer && optionsFarmer.length > 0) {
                            if (options.farmerCultureRegion) {
                                setSelectedFarmer(options.farmerCultureRegionID)                                
                                setFarmer(options.farmerCultureRegion.externalCode + " - " + options.farmerCultureRegion.farmer.name) //+ " (" + options.instructor.externalCode + ")")
                            }
                        }
                        loadParameterTemplateItem(null, (optionsParameterTemplateItem) => {
                            setParameterTemplateItem(optionsParameterTemplateItem)
                            loadConsultAnalyseSampleResultInterpretation(null, (optionsAnalyseSampleResultInterpretation) => {
                                if (optionsAnalyseSampleResultInterpretation) {
                                    setLstAnalyseSampleResultInterpretation(optionsAnalyseSampleResultInterpretation)
                                }
                                loadConsultAnalyseSampleParameterTemplates((optionsAnalyseSampleParameterTemplates) => {
                                    if (optionsAnalyseSampleParameterTemplates) {
                                        setAnalyseSampleParameterTemplates(optionsAnalyseSampleParameterTemplates)

                                        loadConsultAnalyseSampleParameterTemplate((optionsAnalyseSampleParameterTemplate) => {
                                            if (optionsAnalyseSampleParameterTemplate) {
                                                setAnalyseSampleParameterTemplate(optionsAnalyseSampleParameterTemplate[0])
        
                                                if (optionsAnalyseSampleParameterTemplate[0].agronomist) {
                                                    setAgronomist(optionsAnalyseSampleParameterTemplate[0].agronomist)
                                                }
                                                setIsLoading(false)
                                            }
                                        })
                                    }
                                })
                                
                                setSelectedAnalyseSampleParameterTemplate(location.state.analyseSampleParameterTemplateID)

                            })
                        })
                        setAnalyseSampleParameterTemplateInitial()


                    });
                }
                else {
                    setIsLoading(false)
                }

            }
            else {
                setIsLoading(false)
            }

        });

        return () => {
            cancel = true;
            setIsLoading(false)
        }
    }, []);

    const loadConsultAnalyseSampleParameterTemplates = async (callback) => {
        if (location.state && location.state.analyseSampleID) {
            api.get(`AnalyseSample/ConsultAnalyseSampleParameterTemplate?id=${location.state.analyseSampleID}`).then((result) => {
                if (result && result.data) {
                    const options = result.data.response;

                    callback(options);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const loadConsultAnalyseSampleParameterTemplate = async (callback) => {
        if (location.state && location.state.analyseSampleID) {
            api.get(`AnalyseSample/ConsultAnalyseSampleParameterTemplate?id=${location.state.analyseSampleID}&&idAux=${location.state.parameterTemplateID}`).then((result) => {
                if (result && result.data) {
                    const options = result.data.response;

                    callback(options);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const loadAnalyseReport = () => {

        if (analyseSampleParameterTemplateID) {
            setIsLoadingEvents(true);
            
            api.get(`Interpretation/Recommendation/LoadAnalyseSampleParameterTemplateDescriptionObservationList?analyseSampleParameterTemplateID=${analyseSampleParameterTemplateID}&&isForReport=false`)
                .then((result) => {
                    if (result && result.data)
                        loadAnalyseReportWithObservation(result.data.response);
                    else
                        exceptionNotificationAPI('Erro ao carregar o relatório');
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                    setIsLoadingEvents(false);
                });
        }
    }

    const loadAnalyseReportWithObservation = async (observationList) => {
        setIsLoadingEvents(true);
        const combinedList = [];

        await waitForAnalyseSampleParameterTemplates();

        if (analyseSampleReturn && analyseSampleParameterTemplate) {
            api.get(`AnalyseSample/GetReportTemplate`).then((resultReportTemplate) => {
                if (resultReportTemplate && resultReportTemplate.data) {
                    combinedList.push({
                        analyseSampleReturn: analyseSampleReturn,
                        analyseSampleParameterTemplates: analyseSampleParameterTemplate,
                        lstAnalyseSampleResultInterpretation: lstAnalyseSampleResultInterpretation.item1,
                        agronomist: agronomist,
                        lstParameterTemplateItem: lstAnalyseSampleResultInterpretation.item2,
                        observationList: observationList
                    });


                    dispatch({
                        type: 'SET_RECOMENDATION_REPORT_DATA',
                        payload: {
                            combinedList: combinedList,
                            reportTemplate: resultReportTemplate.data.response,
                            isDownloadReport: false,
                        }
                    })
                    
                    localStorage.removeItem('recomendationReportData');
                    localStorage.setItem('recomendationReportData', JSON.stringify({
                        combinedList: combinedList,
                        reportTemplate: resultReportTemplate.data.response,
                        isDownloadReport: false,
                    }));

                    history.push({ pathname: "/reports/recomendationReport" });
                    setIsLoadingEvents(false);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            
            });
        }
        else {
            setIsLoadingEvents(false);
        }
    }

    const waitForAnalyseSampleParameterTemplates = async () => {
        return new Promise(async (resolve) => {
            const checkCondition = async () => {
                if (analyseSampleParameterTemplates) {
                    resolve();
                } else {
                    const optionsAnalyseSampleParameterTemplate = await loadConsultAnalyseSampleParameterTemplates();
                    
                    if (optionsAnalyseSampleParameterTemplate) {
                        setAnalyseSampleParameterTemplates(optionsAnalyseSampleParameterTemplate);
    
                        if (optionsAnalyseSampleParameterTemplate[0].agronomist) {
                            setAgronomist(optionsAnalyseSampleParameterTemplate[0].agronomist);
                        }
    
                        setTimeout(checkCondition, 100);
                    }
                }
            };
    
            await checkCondition();
        });
    };
    
    const loadParameterTemplateItem = async (analyseSampleParameterTemplateID, callback) => {
        var url = `AnalyseSample/ConsultParameterTemplate?`;
        if (analyseSampleParameterTemplateID != null) {
            url += `idAux=${analyseSampleParameterTemplateID}`
        }
        else {
            url += `id=${location.state.parameterTemplateID}`
        }
        api.get(url).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const setAnalyseSampleParameterTemplateInitial = async (callback) => {
        if (location.state) {
            setAnalyseSampleParameterTemplateID(location.state.analyseSampleParameterTemplateID)
        }
    }

    const loadConsultAnalyseSampleResultInterpretation = async (analyseSampleParameterTemplateID, callback) => {
        var url = `AnalyseSample/ConsultAnalyseSampleResultInterpretation?`;
        if (analyseSampleParameterTemplateID != null) {
            url += `idAux1=${analyseSampleParameterTemplateID}`
        }
        else {
            url += `id=${location.state.analyseSampleID}&&idAux=${(location.state.parameterTemplateID)}`
        }
        api.get(url).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadConsultAnalyseSampleResultInterpretationObservationList = async (analyseSampleParameterTemplateID, callback) => {
        api.get(`Interpretation/Recommendation/LoadAnalyseSampleParameterTemplateDescriptionObservationList?analyseSampleParameterTemplateID=${analyseSampleParameterTemplateID}&&parameterTemplateID=${location.state.parameterTemplateID}`).then((result) => {
            if (result && result.data) {
                let observationListFormatted = [];
                let lastID;

                result.data.response.forEach((item, index) => {
                    const actualID = item.analyseSampleParameterTemplateDescriptionID ?? index.toString();
                    const itemFormatted = {
                        ...item,
                        AnalyseSampleParameterTemplateDescriptionIDControlled: actualID,
                        isSaved: item.analyseSampleParameterTemplateDescriptionID ?? false,
                        isNew: !item.analyseSampleParameterTemplateDescriptionID
                    };

                    lastID = index.toString() === actualID ? index.toString() : lastID;
                    observationListFormatted.push(itemFormatted);
                });

                setObservationList(observationListFormatted);
                setCounterNewObservation(+lastID + 1);

                callback(true)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
            callback(true)
        });
    }

    useEffect(() => {
        if (analyseSampleParameterTemplateID) {
            async function fetchData() {
                api.get(`Interpretation/Recommendation/LoadAnalyseSampleParameterTemplateDescriptionObservationList?analyseSampleParameterTemplateID=${analyseSampleParameterTemplateID}`).then((result) => {
                    if (result && result.data) {
                        let observationListFormatted = [];
                        let lastID;

                        result.data.response.forEach((item, index) => {
                            const actualID = item.analyseSampleParameterTemplateDescriptionID ?? index.toString();
                            const itemFormatted = {
                                ...item,
                                AnalyseSampleParameterTemplateDescriptionIDControlled: actualID,
                                isSaved: item.analyseSampleParameterTemplateDescriptionID ?? false,
                                isNew: !item.analyseSampleParameterTemplateDescriptionID
                            };

                            lastID = index.toString() === actualID ? index.toString() : lastID;
                            observationListFormatted.push(itemFormatted);
                        });

                        setObservationList(observationListFormatted);
                        setCounterNewObservation(+lastID + 1);
                    }
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                });
            }
            fetchData();
        }
    }, [analyseSampleParameterTemplateID])

    function handleNextId() {
        setCounterNewObservation(counterNewObservation + 1);
        return counterNewObservation.toString();
    }

    if (isLoading) {
        return (
            <TableLoading />
        )
    }

    const getAnalyseSample = async (callback) => {
        api.get(`AnalyseSample/GetById?id=${(location.state.analyseSampleID)}`).then((result) => {
            if (result && result.data) {
                const options = result.data.response;

                callback(options);

            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadfarmerCultureRegion = async (id, callback) => {
        api.get(`Farmer/GetAllFarmerCultureRegionItems?id=` + (id ? id : '')).then((result) => {
            if (result && result.data) {
                
                const options = result.data.response.map(item => ({ value: item.farmerCultureRegionID, text: `${item.externalCode + " - "} ${item.farmer.name} ` }));

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadSuppliers = async (callback) => {
        api.get(`Supplier/getAllItems`).then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.supplierID, text: `${item.description} ` }));                

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }  

    const loadInstructors = async (callback) => {
        api.get(`AnalyseSample/GetAllInstructors`).then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.instructorID, text: `${item.name} ` }));                

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }    

    const loadBranchs = async (callback) => {
        api.get(`Branch/GetAllBranchs`).then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.branchID, text: `${item.description} ` }));                

                callback(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }    

    const returnAnalyse = async (e) => {
        history.push({ pathname: "/analyseSample/details", state: { analyseSampleID: analyseSampleReturn.analyseSampleID, isCreate: false } })
    };


    const handleAddNewInterpretationObservation = () => {
        let observationListFormatted = [...observationList];

        observationListFormatted.push({
            analyseSampleParameterTemplateID: location.state.analyseSampleParameterTemplateID ? location.state.analyseSampleParameterTemplateID : null,
            analyseSampleParameterTemplateDescriptionIDControlled: handleNextId(),
            description: '',
            isNew: true,
            isActive: true,
            isDeleted: false,
        });

        setObservationList(observationListFormatted);
    }

    const handleChangeObservation = (row, isDeleted, isActive, isToSave, isSaved) => {
        if (isToSave || isDeleted && !row.isNew) {
            async function saveData() {
                setIsLoadingEvents(true);
                api.post('Interpretation/Recommendation/SaveAnalyseSampleParameterTemplateDescription',
                    isDeleted ? { ...row, isDeleted: isDeleted, isActive: isActive } : row ?? {})
                    .then((result) => {
                        if (result.status === 200) {
                            if (isDeleted) {
                                const observationListFormatted = observationList.filter(item =>
                                    item.AnalyseSampleParameterTemplateDescriptionIDControlled !== row.AnalyseSampleParameterTemplateDescriptionIDControlled);

                                setObservationList(observationListFormatted);
                            } else {
                                const observationListFormatted = observationList.map((item) => {
                                    if (item.analyseSampleParameterTemplateID === row.analyseSampleParameterTemplateID
                                        && item.AnalyseSampleParameterTemplateDescriptionIDControlled === row.AnalyseSampleParameterTemplateDescriptionIDControlled) {
                                        return { ...item, isSaved: true };
                                    }
                                    return item;
                                });

                                setObservationList(observationListFormatted);
                            }
                        }
                    })
                    .finally(() => setIsLoadingEvents(false))
                    .catch((error) => {
                        exceptionNotificationAPI(error);
                    });
            }
            saveData();
        } else {
            const observationListFormatted = observationList.map((item) => {
                if (item.AnalyseSampleParameterTemplateDescriptionIDControlled === row.AnalyseSampleParameterTemplateDescriptionIDControlled)
                    return { ...item, description: row.description, isDeleted: isDeleted, isActive: isActive, isSaved: isSaved };
                return item;
            });

            setObservationList(observationListFormatted);
        }
    }

    const handleLoadInterpretationObservationList = () => {

        return observationList.map(row => !row.isDeleted &&
            <Box key={row?.analyseSampleParameterTemplateDescriptionIDControlled}>
                <div style={{ width: "100%", display: 'flex', padding: '16px', borderRadius: '5px' }}>
                    <div style={{ width: '100%' }}>
                        <label style={{ fontFamily: "Montserrat", fontSize: 14, color: "#161C24", fontWeight: 'bold' }}>Observação</label>
                        <div style={{ paddingTop: '10px', width: '100%' }}>
                        {((userRoles.includes(ROLE.AGRONOMIST) || userRoles.includes(ROLE.ADMIN))) ?
                                <textarea placeholder='Digite...' onChange={(e) => handleChangeObservation({ ...row, description: e.target.value ?? row.description }, false, true, false, false)} defaultValue={row.description} className={styles.textAreaRecomendation}>
                                </textarea>
                                : 
                                
                                <textarea readOnly defaultValue={row.description} className={styles.textAreaRecomendation}>
                                </textarea>
                            }
                            
                        </div>
                    </div>
                </div>
                {((userRoles.includes(ROLE.AGRONOMIST) || userRoles.includes(ROLE.ADMIN))) ?
                    <Box>
                        <button
                            style={{ background: 'transparent', border: 'none', color: '#FF3541', fontWeight: 'bold', marginLeft: '15px', marginBottom: 20 }}
                            onClick={() => handleChangeObservation(row, true, false, false, false)}
                        >
                            <img src={tashIcon} style={{ width: '12%', color: 'red', paddingRight: '2px' }} className='icon' />
                            Excluir esta observação
                        </button>
                        <button
                            style={{ background: 'transparent', border: 'none', color: '#198754', fontWeight: 'bold', marginLeft: '15px', marginBottom: 20 }}
                            onClick={() => handleChangeObservation(row, false, true, true, false)}
                            hidden={row?.isSaved}
                        >
                            <img src={okIcon} style={{ width: '10%', color: 'green', paddingRight: '6px' }} className='icon' />
                            Salvar esta observação
                        </button>
                    </Box>
                    : <></>
                }
                
            </Box>
        )
    }

    const handleLoadObservations = (analyseSampleReturn, parameterTemplateItem, _lstAnalyseSampleResultInterpretation, analyseSampleID, analyseSampleParameterTemplateID) => {
        return <>
        {((userRoles.includes(ROLE.AGRONOMIST) || userRoles.includes(ROLE.ADMIN))) ?
            <Box>
                <button
                    style={{ background: 'transparent', border: 'none', paddingBottom: '10px', paddingTop: '20px' }}
                    onClick={() => handleAddNewInterpretationObservation()}
                >
                    <a className={styles.addRecomendation}>+ Adicionar observação </a>
                </button>
            </Box>
            : <></>
        }
            
            <Box>
                {observationList ? handleLoadInterpretationObservationList : <></>}
            </Box>
        </>
    }

    const StyledTabs = styled(Tabs)({
        '& .MuiTabs-indicator': {
            backgroundColor: '#0C8662',
        },
        '&.Mui-selected': {
            color: '#0C8662',
        },
    });

    const StyledTab = styled((props) => <Tab {...props} />)(
        ({ theme }) => ({
            '&:hover': {
                color: '#0C8662',
                opacity: 1,
            },
            '&.Mui-selected': {
                color: '#0C8662 !important',
                fontWeight: '800 !important',
            },
        })
    );

    const handleTab = () => {

        switch (valueTab) {
            case 0:
                return <AnalyseSampleDetailItemInterpretationRecommendationResult place={0} analyseSampleReturn={analyseSampleReturn} parameterTemplateItem={parameterTemplateItem} _lstAnalyseSampleResultInterpretation={lstAnalyseSampleResultInterpretation} analyseSampleID={analyseSampleReturn.analyseSampleID} analyseSampleParameterTemplateID={analyseSampleParameterTemplateID} />
            case 1:
                return <AnalyseSampleDetailItemInterpretationRecommendationResult place={1} analyseSampleReturn={analyseSampleReturn} parameterTemplateItem={parameterTemplateItem} _lstAnalyseSampleResultInterpretation={lstAnalyseSampleResultInterpretation} analyseSampleID={analyseSampleReturn.analyseSampleID} analyseSampleParameterTemplateID={analyseSampleParameterTemplateID} />
            case 2:
                    return handleLoadObservations();
            default:
                return <></>
        }
    }

    return (
        <>
            {
                <>
                    <Container>
                        <Row style={{ marginTop: '30px' }}>
                            <Col md={12} lg={12}>
                                <button className='btn' onClick={() => {
                                    returnAnalyse();
                                }}>
                                    <img src={ArrowLeft} className='icon' />
                                </button>
                                <label style={{ fontFamily: "Montserrat", marginLeft: 20, fontWeight: 700, fontSize: 22, color: "#161C24" }} >{"Análise"}</label>
                                <label style={{ fontFamily: "Montserrat", marginLeft: 20, fontWeight: 700, fontSize: 22, color: "#161C24" }} >{analyseSampleReturn ? analyseSampleReturn.text : ''}</label>
                            </Col>
                        </Row>
                    </Container>
                    <Container className={styles.pagewhite}>
                        <Row>
                            <Col md={3} lg={3} xs={12}>
                                <Form.Group className="mb-3" controlId="SampleNumber">
                                    <Form.Label className={styles.tableLabelsHeader}>Cód. Cliente</Form.Label>
                                    <Form.Control readOnly type="number" defaultValue={analyseSampleReturn ? analyseSampleReturn.sampleNumber : ''} />
                                </Form.Group>
                            </Col>
                            <Col md={3} lg={3} xs={12}>
                                <Form.Group className="mb-3" controlId="FriendlyCode">
                                    <Form.Label className={styles.tableLabelsHeader}>Amostra</Form.Label>
                                    <Form.Control readOnly type="text" defaultValue={analyseSampleReturn ? analyseSampleReturn.friendlyCode : ''} />
                                </Form.Group>
                            </Col>
                            <Col md={3} lg={3} xs={12}>
                                <Form.Group className="mb-3" controlId="DateEmission">
                                    <Form.Label className={styles.tableLabelsHeader}>Data da Emissão</Form.Label>
                                    <Form.Control readOnly type="date" defaultValue={analyseSampleReturn ? analyseSampleReturn.formattedDateEmission : ''} />
                                </Form.Group>
                            </Col>
                            <Col md={3} lg={3} xs={12}>
                                <Form.Group className="mb-3" controlId="DateSample">
                                    <Form.Label className={styles.tableLabelsHeader}>Data da Análise</Form.Label>
                                    <Form.Control readOnly type="date" defaultValue={analyseSampleReturn ? analyseSampleReturn.formattedDateSample : ''} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={12} xs={12}>
                                <Form.Group className="mb-3" controlId="Branch">
                                    <Form.Label className={styles.tableLabelsHeader}>Filial</Form.Label>
                                    <Select isDisabled value={{ label: branch, value: selectedBranch }}
                                        readOnly
                                        style={{ width: "100%" }}>
                                    </Select>                                   
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="Farmer">
                                    <Form.Label className={styles.tableLabelsHeader}>Produtor</Form.Label>
                                    <Select isDisabled value={{ label: farmer, value: selectedFarmer }}
                                        readOnly
                                        style={{ width: "100%" }}>
                                    </Select>
                                </Form.Group>
                            </Col>                            
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="Instructor">
                                    <Form.Label className={styles.tableLabelsHeader}>Instrutor</Form.Label>
                                    <Select isDisabled value={{ label: instructor, value: selectedInstructor }}
                                        readOnly
                                        style={{ width: "100%" }}>
                                    </Select>                                   
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="Supplier">
                                    <Form.Label className={styles.tableLabelsHeader}>Laboratório</Form.Label>
                                    <Select isDisabled value={{ label: supplier, value: selectedSupplier }}
                                        readOnly
                                        style={{ width: "100%" }}>
                                    </Select>                                   
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={12} xs={12}>
                                <Form.Group className="mb-3" controlId="Observation">
                                    <Form.Label className={styles.tableLabelsHeader}>Observações</Form.Label>
                                    <Form.Control as="textarea" rows={5} readOnly defaultValue={analyseSampleReturn ? analyseSampleReturn.observation : ''} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                            <Col md={8} lg={8} xs={12}>
                                <select name='analyseSampleParameterTemplates' value={selectedAnalyseSampleParameterTemplate} text={analyseSampleParameterTemplate} className={styles.search} style={{ width: "100%" }} onChange={(event) => {
                                    var analyseSampleParameterTemplateID = event.target.value;
                                    if (analyseSampleParameterTemplateID != undefined) {
                                        setSelectedAnalyseSampleParameterTemplate(analyseSampleParameterTemplateID)
                                        setIsLoading(true)
                                        loadConsultAnalyseSampleResultInterpretationObservationList(analyseSampleParameterTemplateID, (ObservationList) => {
                                            if (ObservationList) {
                                                loadParameterTemplateItem(analyseSampleParameterTemplateID, (optionsParameterTemplateItem) => {
                                                    setParameterTemplateItem(optionsParameterTemplateItem)
                                                    loadConsultAnalyseSampleResultInterpretation(analyseSampleParameterTemplateID, (optionsAnalyseSampleResultInterpretation) => {
                                                        if (optionsAnalyseSampleResultInterpretation) {
                                                            setLstAnalyseSampleResultInterpretation(optionsAnalyseSampleResultInterpretation)
                                                        }
                                                        setIsLoading(false)
                                                    })
                                                })
                                            }
                                        })
                                    }
                                }}>
                                    {
                                        analyseSampleParameterTemplates ?
                                            analyseSampleParameterTemplates.map(analyseSampleParameterTemplate => {
                                                return <option data-key={analyseSampleParameterTemplate.parameterTemplateID} value={analyseSampleParameterTemplate.analyseSampleParameterTemplateID}>{analyseSampleParameterTemplate.parameterTemplate.description}</option>
                                            })
                                            :
                                            <></>
                                    }
                                </select>
                            </Col>
                            <Col md={4} lg={4} xs={12}>
                                <Button className={styles.button}
                                    onClick={() => loadAnalyseReport()}
                                >
                                    <img src={paper} className='icon' />
                                    <label style={{ height: "auto", cursor: "pointer" }}>Gerar Relatório</label>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="culture">
                                    <Form.Label className={styles.tableLabelsHeader}>Cultura</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="culture">
                                    <Form.Label className={styles.tableLabelsHeader}>Região</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="branch">
                                    <Form.Label className={styles.tableLabelsHeader}>Filial</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="culture">
                                    <Form.Label>{parameterTemplateItem && parameterTemplateItem.culture ? parameterTemplateItem.culture.name : "Todas"}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="culture">
                                    <Form.Label>{parameterTemplateItem && parameterTemplateItem.region ? parameterTemplateItem.region.name : "Todas"}</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="branch">
                                    <Form.Label>{parameterTemplateItem && parameterTemplateItem.branch ? parameterTemplateItem.branch.description : "Todas"}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            <Row>
                                <StyledTabs
                                    value={valueTab ? valueTab : 0}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons={false}
                                    aria-label="harvest-itens"
                                    style={{ marginTop: 20 }}
                                >
                                    <StyledTab key={1} style={{ fontFamily: "Montserrat", fontSize: 14, fontWeight: 500 }} value={0} label={"Interpretação"} />
                                    <StyledTab key={2} style={{ fontFamily: "Montserrat", fontSize: 14, fontWeight: 500 }} value={1} label={"Recomendação"} />
                                    <StyledTab key={3} style={{ fontFamily: "Montserrat", fontSize: 14, fontWeight: 500 }} value={2} label={"Observações"} />
                                </StyledTabs>
                            </Row>
                        }
                        <Row>
                            {valueTab != null ? handleTab() : <></>}
                        </Row>
                    </Container>
                    <Container style={{ marginTop: "20px" }}>
                        <Row>
                            <Col md={2} lg={2} xs={12}>
                                <Button variant="outlined" style={{ width: "100%" }} onClick={() => {
                                    returnAnalyse();
                                }}>
                                    Voltar
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </>
            }
            {isLoadingEvents ? <LoadingEvents /> : <></>}
        </>
    );
}

export default AnalyseSampleDetailItemInterpretationRecommendation;
